import { FormEvent, useEffect, useState } from "react"
import styled from "styled-components"

import { useFetchDeviceCount } from "src/data/devices/queries/deviceQueries"
import { HardwareType } from "src/data/devices/types/deviceTypes"
import { usePostVirtualDevice } from "src/data/homes/queries/virtualDeviceQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHome } from "src/data/organizations/queries/homeQueries"
import { brandText } from "src/ui/colors"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSelect } from "src/ui/MSelect/MSelect"
import { BodyMixin, Heading2Mixin } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"
import { slugify } from "src/utils/genericUtil"

const DEVICE_NAME = "description"
const HARDWARE_TYPE = "hardwareType"
const SOUND_DURATION = "soundDuration"
const DEVICE_MAC_ADDRESS = "deviceMacAddress"

interface AddVirtualDeviceFormData {
  [DEVICE_NAME]: { value: string }
  [HARDWARE_TYPE]: { value: HardwareType }
  [SOUND_DURATION]: { value: string }
}

export function AddVirtualDeviceForm({
  formId,
  homeId,
  afterSubmitSuccess,
}: {
  formId: string
  homeId: string
  afterSubmitSuccess: () => void
}) {
  const { orgId } = useOrganization()
  const postVirtualDevice = usePostVirtualDevice()
  const [hardwareType, setHardwareType] = useState(HardwareType.P3)
  const [name, setName] = useState<string>("")
  const shouldShowSoundDuration = hardwareType !== HardwareType.WLD
  const [soundDuration, setSoundDuration] = useState<number | null>(30)
  const [deviceMacAddress, setDeviceMacAddress] = useState<string | null>(null)

  const fetchHome = useFetchHome({ orgId, homeId })
  const fetchDeviceCount = useFetchDeviceCount({
    orgId,
    filter: { home_ids: [homeId] },
  })

  // Set a useful default name, when fetches are done and when hardware type is changed.
  useEffect(() => {
    const homeName: string = fetchHome.data?.name ?? "home"
    const deviceCount: number = fetchDeviceCount.data ?? 0
    const defaultDeviceName = `${slugify(homeName)}:${hardwareType}-${deviceCount}`
    setName(defaultDeviceName)
  }, [fetchDeviceCount.data, fetchHome.data?.name, hardwareType])

  if (fetchHome.isLoading || fetchDeviceCount.isLoading) {
    return null
  }
  if (fetchHome.isError || fetchDeviceCount.isError) {
    return null
  }

  return (
    <FormBox
      id={formId}
      onSubmit={(e: FormEvent<HTMLFormElement & AddVirtualDeviceFormData>) => {
        e.preventDefault()

        soundDuration &&
          postVirtualDevice.mutate(
            {
              orgId,
              homeId,
              description: name,
              hardwareType,
              soundDuration,
              mac: deviceMacAddress ?? undefined,
            },
            { onSuccess: () => afterSubmitSuccess() }
          )
      }}
    >
      <Title>Add a virtual device</Title>
      <Description>
        This device will appear in your home as any real device would, and upon
        creation you'll get a control panel at the bottom of the screen that you
        can use to control the virtual environment around the device.
      </Description>

      <MSelect
        label="Hardware type"
        autoFocus
        required
        name={HARDWARE_TYPE}
        id="hardwareType"
        value={hardwareType}
        onChange={(value) => {
          setHardwareType(value as HardwareType)
        }}
        options={Object.values(HardwareType)
          .filter((hw) => hw !== HardwareType.NONE && hw !== HardwareType.P1)
          .map((gen) => ({
            value: gen,
            label: getShortname(gen),
          }))}
      />

      <MTextField
        label="Device name"
        name={DEVICE_NAME}
        value={name}
        onChange={(value) => setName(value)}
      />

      {shouldShowSoundDuration && (
        <MTextField
          label="Sound duration in seconds"
          name={SOUND_DURATION}
          type="number"
          min={30}
          max={900}
          value={soundDuration ? String(soundDuration) : ""}
          onChange={(value) => {
            setSoundDuration(value ? parseInt(value) : null)
          }}
          required
        />
      )}
      {!!soundDuration && soundDuration < 300 && shouldShowSoundDuration && (
        <MBanner type="warning">
          The sound duration value chosen here will update the noise profile of
          the home that the virtual device is part of. If you have real devices
          on this home, they might not function as expected if the duration is
          set below 300 seconds.
        </MBanner>
      )}
      {hardwareType === HardwareType.WLD && (
        <MTextField
          label="Device mac address"
          name={DEVICE_MAC_ADDRESS}
          value={deviceMacAddress ?? ""}
          onChange={(value) => setDeviceMacAddress(value)}
        />
      )}
    </FormBox>
  )
}

const FormBox = styled.form`
  display: grid;
  gap: ${spacing.L};
  grid-template-columns: 1fr;
  padding: ${spacing.XS2};
`

const Title = styled.div`
  margin-bottom: ${spacing.M};
  ${Heading2Mixin};
`

const Description = styled.div`
  margin-bottom: ${spacing.XL2};
  ${BodyMixin};
  color: ${brandText};
`

function getShortname(hw: HardwareType): string {
  switch (hw) {
    case HardwareType.A1:
      return "A1 (Eureka)"
    case HardwareType.P2:
      return "M2 (P2)"
    case HardwareType.P3:
      return "M3 (Amarillo)"
    case HardwareType.WLD:
      return "WLD (Water leak detection)"
    default:
      return "Unknown device type"
  }
}
