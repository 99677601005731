import { AddVirtualDeviceForm } from "src/components/Homes/AddVirtualDevice/AddVirtualDeviceForm"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { FullscreenWizard } from "src/ui/Wizard/FullscreenWizard"
import { IWizardStep } from "src/ui/Wizard/wizardTypes"

const FORM_ID = "create-virtual-device-form"

export function AddVirtualDeviceWizard({ homeId }: { homeId: string }) {
  const { goBack } = useRouter()

  const { t, langKeys } = useTranslate()

  function handleClose() {
    goBack({ defaultPath: Routes.Home.location(homeId) })
  }

  const stepper: IWizardStep[] = [
    {
      component: (
        <AddVirtualDeviceForm
          formId={FORM_ID}
          homeId={homeId}
          afterSubmitSuccess={handleClose}
        />
      ),
      nextButtonLabel: t(langKeys.save),
      nextButtonProps: {
        name: "create-device-button",
        form: FORM_ID,
        type: "submit",
        accessKey: "s", // alt+s saves (ctrl+option+s on Mac)
      },
      onNext: handleClose,
    },
  ]

  return (
    <FullscreenWizard
      title={"Add virtual device"}
      steps={stepper}
      currentStep={0} // we only have one step at the moment
      onClose={handleClose}
      onBack={handleClose}
    />
  )
}
